import { Anchor, Box, Button, Grommet, Header, Heading, Image, Main, Meter, Paragraph, ResponsiveContext, Spinner, Text } from "grommet"
import React, { useContext, useState } from "react"
import './fonts.css'
import platformsPic from './platforms.svg'

const theme = {
  global: {
    font: {
      family: "Source Sans Pro"
    },
    colors: {
      brand: "#C70161"
    }
  },
  anchor: {
    color: {
      dark: "brand",
    },
    hover: {
      textDecoration: "none",
      extend: {
        opacity: "0.6"
      }
    },
    extend: {
      transition: "opacity .5s"
    }
  },
  button: {
    border: {
      color: "#C70161",
      radius: "5px"
    },
    primary: {
      color: "brand",
    },
    size: {
      large: {
        border: {
          radius: "8px"
        }
      }
    }
  },
  text: {
    textAlign: "center"
  },
  heading: {
    level: {
      "2": {
        "font": {},
        "small": {
          "size": "30px",
          "height": "36px",
          "maxWidth": "624px"
        },
        "medium": {
          "size": "40px",
          "height": "46px",
          "maxWidth": "816px"
        },
        "large": {
          "size": "60px",
          "height": "66px",
          "maxWidth": "1200px"
        },
        "xlarge": {
          "size": "76px",
          "height": "82px",
          "maxWidth": "1584px"
        }
      }
    }
  }

}


const App = () => {

  // ! Currently not working...
  const screenSize = useContext(ResponsiveContext)

  const callMe = () => {
    window.open("tel:015206482123")
  }

  const sendMeAMail = () => {
    window.open("mailto:kontakt@tpk-it.de")
  }

  return (
    <Grommet theme={theme} full background="dark-1">
      <ResponsiveContext.Consumer>
        {screenSize => (
          <>
            <Header justify="between" align="center" direction={screenSize.includes("small") ? "column" : "row"} margin={{ horizontal: "small", vertical: "small", right: "medium", top: screenSize.includes("small") ? "large" : "small" }}>
              <Box align="center" justify="center">
                <Heading level="2" margin="none">T. Patrick <Text size="parent" color="brand">{"{"}K</Text>akucs<Text size="parent" color="brand">{"}"}</Text></Heading>
                <Text>Freiberufliche Softwareentwicklung</Text>
              </Box>
              <Box direction="row" align="center" gap="small">
                {/* <Anchor color="white">Leistungen</Anchor>
          <Anchor color="white">Über mich</Anchor>
          <Anchor color="white">Referenzen</Anchor> */}
                <Button primary label="Kontakt" size={screenSize.includes("small") ? "medium" : "large"} margin={{ horizontal: "medium" }} onClick={sendMeAMail} />
              </Box>
            </Header>
            <Main>
              <Box margin={{ vertical: "large" }} height="60%" width="100%" justify="center" align="center">
                <Box animation="slideDown" align="center" margin="medium">
                  <Heading
                    level="2"
                    margin={{ bottom: "0" }}
                    textAlign="center"
                  >Verleihen Sie Ihrem Projekt Flügel</Heading>
                  <Text textAlign="center">Mit einer maßgeschneiderten Softwarelösung sind Sie bereit für die Zukunft!</Text>
                </Box>
                <Box width="60%" align="center">
                  <Image src={platformsPic} fit="contain" />
                </Box>
              </Box>
              <Box direction="column" align="center" gap="15px" animation="fadeIn" pad={{ horizontal: "small" }}>
                <Spinner size="medium" />
                <Paragraph>
                  Diese Seite befindet sich momentan im Aufbau. <br />
                  Mail: <Anchor onClick={sendMeAMail}>kontakt@tpk-it.de</Anchor><br />
                  Telefon: <Anchor onClick={callMe}>01520 6482123</Anchor>
                </Paragraph>
              </Box>
            </Main>
          </>
        )}
      </ResponsiveContext.Consumer>
    </Grommet >
  )
}

export default App
